.cta {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    background-color: var(--color-white-dark);
    margin-top: 50px;
}

.cta-backgroud {
    width: 55%;
    height: 240px;
    position: relative;
    top: -100px;
    left: 22.5%;
    border-radius: 4px;
    background-color: var(--color-green-light);
}

.cta-content {
    position: absolute;
    width: 60%;
}

.cta-heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    color: var(--black);
    text-align: left;
}

.cta-text {
    font-size: var(--size-small--accent);
    font-family: var(--font-text);
    color: var(--black);
    text-align: left;
    padding: 10px 0 20px 0;
}