.input {
    position: relative;
    margin-bottom: 15px;
    display: flex;
}
.input__label {
    position: absolute;
    left: 0px;
    top: 5px;
    margin: auto;
    white-space: nowrap;
    transform: translate(0, 0);
    transform-origin: 0 0;
    background: transparent;
    transition: transform 120ms ease-in;
    color: var(--black);
}
.input__field {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 1px solid black;
    color: var(--black);
    background: transparent;
    border-radius: var(--size-radius);
    padding: 5px 10px;
    height: 30px;
}

.input__field--textarea {
    box-sizing: border-box;
    display: block;
    width: 100%;
    border: 1px solid black;
    color: var(--black);
    background: transparent;
    border-radius: var(--size-radius);
    padding: 5px 10px;
    height: 100px;
}

.input__field:focus + .input__label, .input__label {
    transform: translate(0, -140%) scale(.9);
    color: var(--black);
    font-family: var(--font-heading);
    font-size: var(--font-text);
}

input[type="file"] {
    padding: 5px 10px;
    margin: 0;
    border: none;
    position: absolute;
    top: 0px;
    left: 32px;
    overflow-x: fragments;
    width: 90%;
}

.custom-file-upload {
    box-sizing: border-box;
    border: 1px solid black;
    color: var(--black);
    background: transparent;
    border-radius: var(--size-radius);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0px;
    height: 30px;
}

.input__label-file {
    color: var(--white);
    background-color: var(--color-green-action);
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: absolute;
    font-size: var(--size-text);
    font-family: var(--font-text);
}

.input__label-file:hover {
    background-color: var(--color-green-form);
    transition: all .3s ease;
}

.input__label-checkbox {
    box-sizing: border-box;
    display: block;
    color: var(--black);
    background: transparent;
    height: 30px;
    padding: 10px;
    font-size: var(--size-extra-small);
    font-family: var(--font-text);
}

.input__label-checkbox a {
    font-size: var(--size-extra-small);
    font-family: var(--font-heading);
}

.input__field-checkbox {
    box-sizing: border-box;
    display: block;
    width: 20px;
    border: 1px solid var(--color-green-deco);
    color: var(--black);
    background: transparent;
    border-radius: var(--size-radius);
    padding: 5px 10px;
    height: 30px;
}

input[type="checkbox"] {
    margin: 0;
    padding: 11px;
}

input[type="submit"] {
    margin: auto;
    width: 100%;
    height: 45px;
    background-color: var(--color-green-action);
    border: 2px solid var(--color-green-action);
    border-radius: var(--size-radius);
    color: var(--white);
    font-size: 1rem;
    cursor: pointer;
}

input[type="submit"]:hover {
    background-color: var(--color-green-form);
    transition: all .3s ease;
}
