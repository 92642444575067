/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    :root {
        font-size: 14px;
        line-height: 1.4;
        --size-heading: 2.7rem;
        --size-heading--big: 4rem;
        --size-accent: 1.5rem;
        --size-small-heading: 2.3rem;
        --size-small-subheading: 1.3rem;
        --size-text: 1rem;
        --size-small: 1rem;
        --size-extra-small: .9rem;
        --size-small--accent: 1.2rem;
    }

    header {
        height: 60px;
    }

    .logo {
        padding: 0px 20px;
        height: 52px;
    }

    .nav-item-text {
        padding: 5px 10px;
    }

    .hero, .hero-content {
        height: 550px;
    }

    .background-color, .background-video {
        height: 490px;
        max-height: 490px;
        margin-top: 60px;
    }

    .hero-button__wrapper {
        margin-bottom: 50px;
    }

    .hero-video--exit {
        width: 50px;
    }

    .content-menu-with-content {
        min-height: 500px;
        height: auto;
    }

    .content-menu-with-content--wrapper {
        margin-top: 50px;
        margin-bottom: 0px;
        width: 100%;
    }

    .open-job-list--wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
    }

    .open-job-list--filter-item {
        height: 29px;
        padding: 0px 23px;
    }

    .open-job-list--filter {
        margin-top: 10px;
    }

    .open-job-list--list {
        margin-top: 15px;
        gap: 15px;
    }

    .open-job-list--list-job {
        height: 140px;
    }

    .open-job-list--list-job-overview {
        padding: 0px 25px;
    }

    .center-button, .departments .center-button {
        padding-bottom: 50px;
    }

    .cta {
        height: 220px;
    }

    .cta-backgroud {
        height: 160px;
        top: -75px;
    }

    .cta-text {
        padding: 10px 0px
    }

    .departments-heading {
        padding-top: 50px;
    }

    .department-slider {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .department-slider--card {
        height: 250px;
    }

    .slider__headline-wrapper {
        width: 100%;
    }

    .button {
        height: 40px;
        min-width: 140px;
    }

    .background-img-cta {
        height: 450px;
    }

    .success-stories--heading {
        padding-top: 50px;
    }

    .success-stories--content {
        margin: 25px 0px 30px 0px;
    }

    .success-stories-heading-wrapper {
        width: 100%;
    }

    .success-stories--content-wrapper {
        padding-top: 0px;
        width: 100%;
    }

    .success-stories--content-img {
        height: 250px;
    }

    .background-decoration--left {
        height: 300px;
        width: 300px;
    }

    .background-decoration--right {
        width: 250px;
        height: 500px;
        background-color: var(--color-green-hover);
        right: -100px;
        top: 85px;
    }

    .quicklinks {
        padding: 25px;
    }

    .quicklink-content {
        height: 60px;
        padding: 0px 20px;
    }

    .arrow {
        top: 25%;
    }

    .icon-list--container {
        padding-top: 50px;
        padding-bottom: 25px;
        width: 100%;
    }

    .icon-list--headline {
        padding-bottom: 25px;
    }

    .icon-list--item {
        padding: 20px 30px;
        margin-bottom: 20px;
    }

    .style-bubble--top-right--action {
        top: 61px;
        right: 63px;
        width: 270px;
        height: 427px;
        border-radius: 75px;
    }

    .style-bubble--bottom-left {
        bottom: 320px;
        left: -60px;
        width: 300px;
        height: 300px;
        border-radius: 48px;
    }

    .icon-list--item-icon {
        width: 55px;
        height: 55px;
        padding: 10px;
    }

    .content {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .content-media {
        height: 250px;
    }

    .content-media-img {
        height: 250px;
    }

    .content-media-video {
        max-height: 350px;
    }

    .content-text-image {
        padding: 20px 0px;
    }

    .swiper-button-prev {
        left: 10px !important;
    }

    .swiper-button-next {
        right: 10px !important;
    }

    .swiper-button-next, .swiper-button-prev {
        padding: 27px;
    }

    .category-slider {
        padding-top: 20px;
        padding-bottom: 25px;
    }

    .category-slider-wrapper {
        padding: 0px;
        width: 85%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .category-slider-single-img {
        height: 150px;
    }

    .slider-slider .category-slider-single-img {
        height: 200px;
    }

    .slider-slider .category-slider-wrapper {
        width: 100%;
        padding: 20px;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .category-slider--card-link {
        padding: 0px 10px 10px 10px;
    }

    .category-slider-single-card--text {
        padding: 5px 10px;
    }

    .team-bubble-wrapper {
        transform: scale(.9) translateY(-70px);
        padding: 0px;
    }

    .single-job--filter-item {
        padding: 6px 27px;
    }

    .job--tags {
        padding: 15px;
    }

    .job {
        padding-top: 100px;
    }

    .job--container {
        width: 100%;
        background-color: transparent;
        box-shadow: none;
    }

    .input__field {
        height: 30px;
    }

    .application--form-container {
        width: 100%;
    }

    input[type="submit"] {
        height: 35px;
    }

    .custom-file-upload {
        height: 30px;
    }
}