/** hero */

.hero {
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.hero--container {
    height: auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 0;
}

.hero .pimcore_editable_video {
    width: auto;
}

.background-video {
    position: absolute;
    min-width: 100%;
    height: 650px;
    max-height: 650px;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.6;
    object-fit: cover;
}

.background-video img {
    margin-top: 0;
}

.background-color {
    position: absolute;
    min-width: 100%;
    height: 650px;
    max-height: 650px;
    z-index: -2;
    top: 0;
    left: 0;
    background-color: var(--color-green-dark);
}

/** headline */

.headline {
    width: 100%;
    color: var(--white);
    font-family: var(--font-heading);
    font-size: var(--size-heading--big);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.headline-text {
    font-family: var(--font-heading);
    font-size: var(--size-heading--big);
    text-align: center;
    position: absolute;
}

.headline-animation span {
    opacity: 0;
    transition: all .4s ease;
    transform: translateY(30px);
    display: inline-block;
}

.headline-animation span.fade {
    opacity: 1;
    transform: translateY(0px);
}

.headline-background-text {
    font-size: 15rem;
    position: relative;
    bottom: 0;
    z-index: -1;
    font-family: var(--font-heading);
    opacity: 0.2;
}

.expand {
    transform: scale(2);
    transition: all .3s ease-in-out;
    color: var(--color-green-action);
    opacity: 1;
}

.button {
    height: 50px;
    min-width: 170px;
    width: fit-content;
    border: none;
    border-radius: 5px;
    background-color: var(--color-green-action);
    color: var(--white);
    cursor: pointer;
    position: relative;
    transition: all .3s ease-in-out;
    padding: 0px 25px;
}

.button:hover {
    background-color: var(--color-green-form);
}

.button:active {
    transform: scale(0.9);
}

/** scrollanimator */

.scroll-animator {
    height: 40px;
    width: 23px;
    border-radius: 100px;
    background-color: var(--color-green-action);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 20px;
    margin: 0 auto;
    z-index: 4;
}

.scroll-animator__moving {
    height: 25px;
    width: 5px;
    border-radius: 3px;
    background-color: var(--color-white-dark);
    position: relative;
    top: 15px;
    animation: moveupdown 2s ease-in-out infinite;
}

@keyframes moveupdown {
    0%, 100% {
        top:20px
    }
    50% {
        top: 0px
    }
}

/** hero-small */
.hero-small {
    width: 80%;
    height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    margin-top: 100px;
}

.hero-small--background-img {
    position: absolute;
    min-width: 100%;
    height: 335px;
    max-height: 900px;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0.6;
    object-fit: cover;
}

.hero-small--background-color {
    position: absolute;
    min-width: 100%;
    height: 335px;
    max-height: 900px;
    z-index: -2;
    top: 0;
    left: 0;
    background-color: var(--color-green-dark);
}

/** headline with text and media */

.headline-text-media {
    background-color: var(--color-green-light);
    width: 100%;
    display: flex;
    flex-direction: column;
    height: min(800px, 90vh);
    justify-content: flex-end;
}

.headline-text-media--container {
    padding-top: 100px;
    height: 80%;
    width: 80%;
}

.headline-text-media--headline {
    font-size: 5rem;
    font-weight: bolder;
    color: var(--black);
}

.headline-text-media--text-media {
    padding: 50px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.headline-text-media--text {
    width: 45%;
    line-height: 1.5;
}

.headline-text-media--media-container {
    width: 45%;
}

.headline-text-media--media {
    width: 100%;
    max-height: 100%;
    height: fit-content;
}

.hero-button__wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 100px;
    position: relative;
    z-index: 3;
}

.headline-button {
    margin-right: 20px;
}

.headline-button--extra {
    background-color: var(--color-green-dark);
}

.hero-video__overlay {
    display: none;
    visibility: hidden;
}

.hero-main-video {
    display: flex;
    visibility: visible;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: auto;
    margin: auto;
    background-color: var(--color-green-light);
    padding: 10px;
}

.hero-video__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-video__overlay--active {
    display: flex;
    visibility: visible;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 10;
    background: rgba(0,0,0,0.7);
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.hero-video--exit {
    cursor: pointer;
    width: 70px;
}

.hero-headline {
    text-align: left;
}

.hero-video-play-button {
    display: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 75px;
}

.hero-video-play-button--active {
    display: block;
    visibility: visible;
}

.hero-text {
    padding: 10px 0px 20px 0px;
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--white);
    width: 50%;
}