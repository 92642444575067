/** application form */

.style-bubble--top-right-outside {
    position: absolute;
    top: 8px;
    right: -200px;
    width: 364px;
    height: 503px;
    background-color: var(--color-green-light);
    border-radius: 142px;
    z-index: -1;
}

.style-bubble--bottom-left-outside {
    position: absolute;
    bottom: -165px;
    left: -290px;
    width: 400px;
    height: 400px;
    background-color: var(--color-green-deco);
    border-radius: 142px;
    z-index: -1;
}

.application--form {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.application--form-container {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    background-color: var(--white);
    border-radius: 4px;
}

.application--form-text__preheading {
    font-family: var(--font-heading);
    color: var(--color-green-action);
}

.application--form-text__heading {
    font-family: var(--font-heading);
    font-size: var(--size-small-subheading);
    color: black;
    text-align: left;
}

.application--form-text__text {
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
    padding: 5px 0px;
}

.application--form-text__text a {
    text-decoration: underline;
}

.application--form-heading-container {
    background-color: var(--color-green-deco);
    width: 40%;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.application--form-heading {
    font-size: var(--size-heading--big);
    font-family: var(--font-heading);
    text-align: left;
    color: black;
    width: 100%;
    padding: 40px;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.application--form-form-container {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    margin-top: 20px;
}

.application--form-form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.application--form-form input {
    margin-bottom: 15px;
}

.application--form-form select {
    margin-bottom: 20px;
}

.application--form-form textarea {
    padding-bottom: 7px;
    margin-bottom: 20px;
}

.application--form-container--initativ {
    padding-top: 200px;
}

.application--form__input-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.input--shared {
    width: 47%;
}
