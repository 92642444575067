/** big-headline */

.big-headline {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 100px 0px 200px 0px;
}

.big-headline--container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
}

.big-headline--text {
    font-size: 1.2rem;
    font-weight: bolder;
    color: #52B788;
}

.big-headline--headline {
    font-size: 4rem;
    color: var(--black);
    padding: 20px 0px;
    font-weight: bolder;
}

.big-headline--background-icon {
    position: absolute;
    color: #F8CFD3;
    width: 250px;
    top: 0;
    right: 50px;
    z-index: -1;
    opacity: 0.2;
}