/** departments */

.departments {
    width: 100%;
    background-color: var(--color-green-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.departments-heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    padding-top: 70px;
    color: var(--black);
}

.department-slider {
    width: 80%;
    padding-top: 30px;
    padding-bottom: 50px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.departments .center-button {
    padding-bottom: 70px;
}

.department-slider--card {
    width: 30%;
    height: 270px;
    background-color: var(--color-green-light);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease;
    position: relative;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.department-slider--card:hover {

    transform: scale(1.05) !important;
    margin: 0px 10px 0px 10px;
    transition: all .3s ease;
}

.department-slider--card-heading {
    padding: 30px 20px 0px 20px;
    font-size: var(--size-text);
    font-family: var(--font-heading);
    color: var(--black);
}

.department-slider--card-text {
    padding: 15px 20px 0px 20px;
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
    text-align: left;
}

.department-slider--card-link {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: auto;
}

.department-slider--card-link-text {
    font-weight: lighter;
    color: var(--black);
}

.department-slider--card-link-button {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: #52B788;
    position: relative;
    left: 10px;
}

.department-slider--icon-container {
    width: 90px;
    height: 90px;
    border-radius: 100px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -50px;
    z-index: 3;
    background-color: #74C69D;
    display: none;
    visibility: hidden;
}

.department-slider--icon-container-icon {
    padding: 10px;
    display: none;
    visibility: hidden;
}


.department-link {
    width: 100%;
    height: 100%;
    display: block;
}


/** arrow */
.arrow {
    cursor: pointer;
    /*height: 15px;*/
    /*left: 50%;*/
    /*position: relative;*/
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.1s;
    /*width: 15px;*/
}
.arrow-top, .arrow-bottom {
    background-color: #081C15;
    height: 2px;
    left: 6px;
    position: absolute;
    top: 50%;
    width: 50%;
}
.arrow-top:after, .arrow-bottom:after {
    background-color: #74C69D;
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.15s;
}
.arrow-top {
    transform: rotate(45deg);
    transform-origin: bottom right;
}
.arrow-top:after {
    left: 100%;
    right: 0;
    transition-delay: 0s;
}
.arrow-bottom {
    transform: rotate(-45deg);
    transform-origin: top right;
}
.arrow-bottom:after {
    left: 0;
    right: 100%;
    transition-delay: 0.15s;
}
.arrow:hover .arrow-top:after {
    left: 0;
    transition-delay: 0.15s;
}
.arrow:hover .arrow-bottom:after {
    right: 0;
    transition-delay: 0s;
}
.arrow:active {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
}
