/** animation */
.parallax-left, .parallax-right, .parallax-bottom, .parallax-top {
    transform: translate3d(0px, 0px, 0px);
    opacity: 0;
    transition: all .7s ease-in-out;
}

.parallax-instant--bottom {
    opacity: 0;
    transform: translate3d(0px, 30px, 0px);
    transition: all .7s ease-in-out;
}

.parallax-instant--top {
    opacity: 0;
    transform: translate3d(0px, -100%, 0px);
    transition: all 1.4s ease-in-out;
}

.modal {
    display: none;
    visibility: hidden;
}

.modal-active {
    display: flex;
    visibility: visible;
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background-color: var(--color-green-light);
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.modal-active .modal__wrapper {
    height: 300px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-active .modal__text {
    font-size: var(--size-small-subheading);
    font-family: var(--font-heading);
    text-align: left;
}

.modal-active .loader {
    margin-top: 20px
}

/* Loader */

.loader {
    border: 10px solid var(--color-green-action);
    border-top: 10px solid var(--color-green-dark);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}