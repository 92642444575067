.category-slider {
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
}

.category-slider-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;
    overflow: hidden;
    padding-top: 25px;
    padding-bottom: 25px;
}

.category-slider--headline {
    width: fit-content;
    margin: auto;
    color: var(--black);
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    padding-bottom: 0px;
}

.category-slider-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0px 50px 0px;
}

.category-slider-single-container {
    position: relative;
    width: 31%;
    height: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    transition: all .3s ease;
}

.category-slider-main-link {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    color: transparent !important;
}

.category-slider-single-container:hover {
    transform: scale(1.02) !important;
    transition: all .3s ease;
}

.category-slider .center-button {
    padding-bottom: 0px;
}

.category-slider-single-img {
    width: 100%;
    height: 220px;
    object-fit: cover;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
}

.category-slider-single-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 250px;
    position: relative;
}

.category-slider-single-card--heading {
    font-size: var(--size-small--accent);
    font-family: var(--font-heading);
    color: var(--black);
    padding: 5px 10px 5px 20px;
    position: absolute;
    top: -55px;
    background-color: var(--color-green-light);
}

.category-slider-single-card--text {
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
    text-align: left;
    padding: 10px 20px;
}

.category-slider--card-link {
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.category-slider--card-link-text {
    font-size: var(--size-text);
    font-family: var(--font-accent);
    color: var(--black);
}

.category-slider--card-link-button {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: #52B788;
    position: relative;
    left: 10px;
}

.container-hidden {
    display: none;
    visibility: hidden;
}


/** arrow */

.category-slider-arrow {
    cursor: pointer;
    /*height: 15px;*/
    /*left: 50%;*/
    position: relative;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.1s;
    /*width: 15px;*/
}

.category-arrow {
    cursor: pointer;
    /*height: 15px;*/
    /*left: 50%;*/
    position: relative;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: transform 0.1s;
    /*width: 15px;*/
}

.category-arrow:hover .arrow-top:after {
    left: 0;
    transition-delay: 0.15s;
}

.category-arrow:hover .arrow-bottom:after {
    right: 0;
    transition-delay: 0s;
}

.category-arrow:active {
    transform: translateX(0%) translateY(0%) scale(0.9);
}

.category-slider-action--left {
    width: 70px;
    height: 70px;
    background-color: #EFFAF1;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    transform: rotate(180deg);
    margin: auto;
}

.category-slider-action--left:active {
    transform: rotate(180deg) scale(0.9);
}

.category-slider-action--right:active {
    transform: scale(0.9);
}

.category-slider-action--right {
    width: 70px;
    height: 70px;
    background-color: #EFFAF1;
    border-radius: 100%;
    cursor: pointer;
    position: relative;
    margin: auto;
}

.slider-arrow {
    position: absolute;
    top: 32px;
    width: 70%;
    height: 5px;
    background-color: #52B788;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    animation: arrow 700ms linear infinite;
    right: 14px;
}

.slider-arrow::after, .slider-arrow::before {
    content: '';
    position: absolute;
    width: 60%;
    height: 5px;
    right: -8px;
    background-color: #52B788;
}

.slider-arrow::after {
    top: -8px;
    transform: rotate(45deg);
}

.slider-arrow::before {
    top: 9px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
    transform: rotate(-45deg);
}

.arrow-hidden {
    display: none;
    visibility: hidden;
}