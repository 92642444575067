/** quicklinks */

.quicklinks {
    width: 100%;
    background-color: var(--color-white-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

.quicklinks-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
    padding: 30px 0;
    width: 350px;
    height: 100%;
}

.quicklinks-heading {
    font-size: var(--size-small-subheading);
    font-family: var(--font-heading);
    color: var(--black);
}


.quicklink-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    border: 1px solid var(--black);
    border-radius: 100px;
    padding: 20px;
    cursor: pointer;
}

.quicklink-content:hover {
    background-color: var(--color-green-hover);
    transition: background-color .3s ease;
}

.quicklink-name {
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
}