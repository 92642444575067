.header-mobile {
    visibility: hidden;
    position: fixed;
    transition: all .5s ease;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 7;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
}

.header-mobile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: var(--color-green-light);
}

.nav-mobile {
    transform: translateX(+120%);
    transition: .3s all ease-in-out;
    background-color: var(--color-green-light);
    width: 100%;
    height: 0;
}

.nav-mobile-active {
    transform: translateX(0%);
    visibility: visible;
    height: 100vh;
    min-height: 100vh;
}

.nav-mobile-list {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
}

.nav-mobile-item {
    padding: 20px 0px 0px 0px;
    border-bottom: 1px solid black;
    width: 100%;
    position: relative;
}

.nav-mobile-active .nav-item-active a::before {
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 100%;
    margin-right: 5px;
    background-color: var(--color-green-action);
    opacity: 1;
    display: inline-block;
}

.nav-mobile-item-text {
    font-size: var(--size-small--accent);
    font-family: var(--font-text);
    color: var(--black);
}

.button-mobile {
    margin: 0px 20px 0px 20px;
}