/** navigation */

header {
    position: fixed;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: flex-start;
    background-color: transparent;
    transition: all .3s ease;
    z-index: 3;
    border-bottom: 8px solid var(--color-green-action);
}

.sticky {
    background-color: var(--color-green-light);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
    margin-top: 0;
    transition: all .3s ease;
}

.sticky .nav-item-text {
    color: var(--black);
}

.nav {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.nav-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    max-width: 80em;
    width: 80%;
    margin-bottom: 0;
}

.nav-item {
    color: var(--white);
    font-family: var(--font-accent);
    font-size: var(--size-text);
    transition: all .3s ease-in;
}

.logo {
    background-color: var(--color-green-dark);
    padding: 0px 40px;
    height: 62px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo-text {
    font-size: var(--font-text);
    color: var(--white);
}

.nav-item-jobs {
    position: absolute;
    border-radius: 100%;
    background-color: var(--color-green-action);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    font-size: var(--size-extra-small);
    font-family: var(--font-accent);
    color: var(--white);
    bottom: 10px;
    left: 65px;
    top: -14px;
}

.nav-item.jobs {
    position: relative;
}

.nav-mobile-item.jobs {
    position: relative;
}

.nav-item-text {
    color: var(--white);
    display: block;
    padding: 10px;
    text-align: center;
    position: relative;
}

.nav-item-text::after {
    content: "";
    width: 3px;
    height: 3px;
    background-color: var(--color-green-action);
    position: absolute;
    left: calc(50% - 4px);
    bottom: 0px;
    opacity: 0;
}

.nav-item-text:hover::after {
    content: "";
    width: 100%;
    height: 6px;
    background-color: var(--color-green-dark);
    position: absolute;
    left: 0%;
    bottom: -3px;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    transition: width .7s ease-in-out, left .7s ease-in-out, height .3s ease-in-out .7s;
}

.nav-item-active:hover .nav-item-text::after  {
    opacity: 0;
}

.logo:hover {
    background-color: var(--color-green-action);
}

.nav-item-active {
    border-bottom: 4px solid var(--color-green-dark);
}

/** das sind wir **/

/** header for light backgrounds */
.header--background-color {
    background-color: var(--color-green-light);
}

.header--background-color .nav-item-text {
    color: var(--black);
}

.header--background-color .nav-item-jobs {
    background-color: var(--color-green-action);
}

.header-mobile {
    display: none;
    visibility: hidden;
}

.button-text {
    color: var(--white);
    font-family: var(--font-heading);
    font-size: var(--size-text);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
}

a .button-text {
    color: var(--white);
    font-family: var(--font-heading);
    font-size: var(--size-text);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-transform: uppercase;
}