/** single success-story */

.hero-success {
    width: 100%;
    height: min(90vh, 800px);
    background-color: #E8F1F2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hero-success--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    align-items: flex-end;
    margin: auto;
}

.hero-success--content {
    width: 55%;
    padding: 50px 50px 30px 50px;
}

.hero-success--content-heading {
    font-size: 5rem;
    font-weight: bolder;
    color: #2D6A4F;
    padding-bottom: 30px;
}

.hero-success--content-text {
    padding-bottom: 100px;
    font-size: 1.2rem;
    line-height: 1.4;
    color: #2D6A4F;
}

.hero-success--image-container {
    width: 40%;
    height: 100%;
}

.hero-success--image {
    min-height: 100%;
    width: 100%;
    object-fit: none;
}