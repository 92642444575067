/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

:root {
    color: var(--black);
    background-color: white;
    font-size: 16px;
    line-height: 1.4;
}
body {
    overflow-x: hidden;
    text-align: justify;
}

body html {
    overscroll-behavior: none;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

*, *:after, *:before {
    box-sizing: border-box;
}

.maxwidthcontainer {
    max-width: 80em;
    margin: 0 auto;
    padding: 20px;
}

a:link, a:visited,  a:hover, a:active
{
    color: #000000;
    text-decoration: none;
}

html {
    font-family: "Helvetica Neue", Arial, sans-serif;
    overflow-x: hidden;
}

.overflow {
    overflow-x: hidden;
    position: relative;
}

:root {
    --color-white-dark: #E8F1F2;
    --color-green-action: #52B788;
    --color-green-light: #EFFAF1;
    --color-green-hover: #95D5B2;
    --color-green-dark: #081C15;
    --color-green-deco: #D8F3DC;
    --color-green-form: #40916C;
    --white: white;
    --black: black;
    --font: 'Helvetica Neue', Arial, sans-serif;
    --font-heading: 'Open Sans 700', Arial, sans-serif;
    --font-text: 'PT Sans', Arial, sans-serif;
    --font-accent: 'Roboto 300', Arial, sans-serif;
    --font-italic: 'PT Sans Italic', Arial, sans-serif;
    --size-heading: 3.3rem;
    --size-heading--big: 5rem;
    --size-accent: 1.7rem;
    --size-small-heading: 2.5rem;
    --size-small-subheading: 1.5rem;
    --size-text: 1rem;
    --size-small: 1rem;
    --size-extra-small: .9rem;
    --size-small--accent: 1.2rem;
    --color-light: white;
    --color-dark: #212121;
    --color-signal: #fab700;
    --color-background: var(--color-light);
    --color-text: var(--color-dark);
    --color-accent: var(--color-signal);
    --size-bezel: .5rem;
    --size-radius: 4px;
    --orange-main: #eb452e;
    --orange-light: #fbead4;
    --green-light: #7fcdb0;
    --green-dark: #1a5c4f;
    --white: white;
    --black: black;
    --accent-blue: #aad9e2;
    --accent-pink: #eeced6;
    --accent-green: #7fcdb0;
}

.pimcore_editable_video {
    height: 100%;
    width: 100%;
}