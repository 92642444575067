/** open-job-list */

.open-job-list {
    width: 100%;
    background-color: white;
}

.open-job-list--wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 100px;
    padding-bottom: 100px;
}

.small-heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    color: var(--black);
}

.open-job-list--filter {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
    flex-wrap: wrap;
}

.open-job-list--filter-item {
    padding: 0px 35px;
    border-radius: 5px;
    min-width: 120px;
    height: 37px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-green-deco);
    margin-right: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    white-space: nowrap;
}

.open-job-list--filter-item--filter-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 37px;
    margin-right: 20px;
    font-size: var(--size-text);
    font-family: var(--font-text);
}

.open-job-list--filter-item:active {
    transform: scale(0.9);
    transition: all .3s ease;
}

.open-job-list--filter-item:hover {
    background-color: var(--color-green-hover);
}

.open-job-list--filter-item__active {
    background-color: var(--color-green-action);
}

.open-job-list--filter-item__active:hover {
    background-color: var(--color-green-action);
}

.open-job-list--list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    gap: 20px;
}

.open-job-list--list-job {
    background-color: transparent;
    /*border: 1px solid #52B788;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 170px;
    border-radius: 5px;
    transition: height .7s ease-in-out;
    /*box-shadow: rgba(100, 100, 111, 0.15) 0px 7px 29px 0px;
    box-shadow: rgba(149, 157, 165, 0.15) 0px 8px 24px;*/
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 25px 0px;
}

.open-job-list--job__inactive {
    display: none;
    visibility: hidden;
}

.description {
    opacity: 0;
    visibility: hidden;
    display: none;
    font-size: var(--size-text);
    font-family: var(--font-text);
}

.open-job-list--list-job:hover {
    background-color: #EFFAF1;
    transition: all .5s ease-in-out;
    height: 300px;
    padding: 50px 0px;
}

.open-job-list--list-job:hover .description {
    opacity: 1;
    display: block;
    visibility: visible;
    padding: 5px 0px;
}

.open-job-list--list-job:hover .open-job-list--list-job-overview {
    margin: 0;
}

.open-job-list--list-job-overview {
    margin: auto;
    padding: 0px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 65%;
}
.center-button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 100px;
}

.open-job-list--list-job-title {
    text-align: left;
    font-size: var(--size-small-subheading);
    font-family: var(--font-heading);
    color: var(--black);
    display: flex;
    flex-wrap: wrap;
}

.open-job-list--list-job-title--title {
    width: 100%;
}

.open-job-list--list-job-title--seperator {
    display: none;
    visibility: hidden;
}

.open-job-list--list-job-title--department {
    font-family: var(--font-accent);
    text-align: left;
    font-size: var(--size-small--accent);
}

.open-job-list--list-job-tags {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.open-job-list--list-job-tags--container {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.open-job-list--list-job-tags--tag {
    font-size: var(--size-text);
    font-family: var(--font-accent);
}

.open-job-list--list-job-tags--button {
    padding: 5px;
    max-width: 5px;
    max-height: 5px;
    margin: auto;
    margin-right: 5px;
    display: inline-block;
    background-color: var(--color-green-action);
    border-radius: 100px;
}

.open-job-list--list-job-cta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 35%;
}

.open-job-list--list-job-cta--small {
    cursor: pointer;
    font-size: var(--size-text);
    font-family: var(--font-accent);
    color: var(--black);
    transition: all .3s ease-in-out;
    position: relative;
}

.open-job-list--list-job-cta--small::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: var(--color-green-dark);
    position: absolute;
    left: 0;
    bottom: 0px;
    opacity: 1;
}

.open-job-list--list-job-cta--small:hover::after {
    content: "";
    width: 100%;
    height: 3px;
    background-color: var(--color-green-dark);
    position: absolute;
    left: 0%;
    bottom: 0px;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    transition: width .7s ease-in-out, left .7s ease-in-out, height .3s ease-in-out;
}

.content-menu-with-content--menu-items__active:hover::after {
    opacity: 0;
}

.open-job-list--list-job-cta--seperator {
    margin: 0 20px;
    height: 60px;
    border: 3px solid #081C15;
}

.open-job-list--list-job-cta--button {
    border-radius: 100px;
    padding: 15px 30px;
    background-color: #52B788;
    transition: all .3s ease-in-out;
    font-size: var(--size-text);
    font-family: var(--font-accent);
    color: var(--white);
}

.open-job-list--list-job-cta--button:hover {
    background-color: #95D5B2;
}

.open-job-list--list-job-cta--button:active {
    transform: scale(0.8);
}
