.team {
    width: 100%;
    height: auto;
    background-color: var(--white);
    position: relative;
}

.team-headline {
    font-family: var(--font-heading);
    font-size: var(--size-small-heading);
    color: var(--black);
    text-align: center;
    padding-top: 50px;
}

.team-bubble-wrapper {
    padding: 0px 0px 100px 0px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* animation: 20s move infinite; */
}

.team-bubble-wrapper--single {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    animation: 15s move-up infinite;
    width: 140px;
}

.move-down {
    animation: 3s move-down infinite;
}

.team-bubble--styling {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    /* background-color: #52B788; */
    transition: all 1s ease;
}

.team-bubble--styling:hover {
    transform: scale(1.2);
}


.team-bubble--styling--big {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    /* background-color: #95D5B2; */
    transition: all .3s ease;
}

.team-bubble--styling--big:hover {
    transform: scale(.8);
}

.team-bubble--styling--small {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #52B788;
    transition: all .5s ease;
}

.team-bubble--styling--small:hover {
    transform: scale(.8);
}

.team-bubble--text-text {
    font-family: var(--font-heading);
    font-size: var(--size-small--accent);
    padding: 20px;
    color: var(--black) !important;
    text-align: center;
}

.team-bubble--text {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .5s ease;
}

.team-bubble--text:hover {
    transform: scale(2.5);
}

.team-bubble-wrapper--single div:nth-of-type(2n+2) img {
    border-radius: 100%;
    animation: 5s move-sideways-diff infinite, 10s change-border infinite;
}

.team-bubble--img {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
    transition: all 2s ease;
    animation: 3s move-sideways infinite;
}

.team-bubble--img:hover {
    transform: scale(2);
}

.team-bubble--img--small {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
    transition: all .3s ease;
}

.team-bubble--img--small:hover {
    transform: scale(1.2);
}

.team-bubble--img--big {
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
    transition: all .7s ease;
    animation-name: change;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.change--bg {
    animation-name: change-bg;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.change--bg2 {
    animation-name: change-bg;
    animation-duration: 10s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.change--bg3 {
    animation-name: change-bg2;
    animation-duration: 15s;
    animation-delay: .3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes expand {
    0%   {left: 0;}
    25%   {left: 20px;}
    50%   {left: 0px;}
    75%   {left: -20px;}
    100%  {left: 0px;}
}

@keyframes change {
    0%   {border: 20px solid var(--orange-light);}
    25%  {border: 20px solid var(--accent-pink);}
    50%  {border: 20px solid var(--accent-blue);}
    75%  {border: 20px solid var(--accent-green);}
    100%  {border: 20px solid var(--orange-light);}
}

@keyframes change-border {
    0%   {border: 10px solid var(--orange-light);}
    25%  {border: 10px solid var(--accent-pink);}
    50%  {border: 10px solid var(--accent-blue);}
    75%  {border: 10px solid var(--accent-green);}
    100%  {border: 10px solid var(--orange-light);}
}

@keyframes move {
    0%
    {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(+10%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes move-up {
    0%
    {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(+5%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes move-down {
    0%
    {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(-1%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes move-sideways {
    0%
    {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(-5%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes move-sideways-diff {
    0%
    {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(+3%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes change-bg {
    0%  {background-color:var(--accent-blue);}
    25%   {background-color:var(--orange-light);}
    50%  {background-color:var(--accent-green);}
    75%  {background-color:var(--accent-pink);}
    100%  {background-color:var(--accent-blue);}
}

@keyframes change-bg2 {
    0%  {background-color:var(--accent-green);}
    25%  {background-color:var(--accent-blue);}
    50%  {background-color:var(--orange-light);}
    75%  {background-color:var(--accent-pink);}
    100%   {background-color:var(--orange-light);}
}

.magnet {
    /*position: fixed;*/
    z-index: 1;
    transition: all .5s ease;
}

.magnet-img {
    transition: all .5s ease;
}

.magnet-img:hover {
    transform: scale(2);
}


.button__like-text {
    background: #D8F3DC;
    color: white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.2s transform;
}

.bubble {
    position: fixed;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.4);
    transition: height 0.4s, width 0.4s, background-color 0.4s, all 0.2s;
    z-index: 9999;
}