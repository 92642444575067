.inactive {
    display: none;
    visibility: hidden;
}

.news__swiper {
    width: 100%;
}

.swiper2 {
    width: 100%;
}

.swiper-slide {
    width: 100%;
}

.swiper-wrapper {
    width: 100%;
    padding: 0;
}

.swiper-button-prev {
    left: 5% !important;
}

.swiper-button-next {
    right: 5% !important;
}

.swiper-button-next, .swiper-button-prev {
    color: var(--color-green-action) !important;
    border-radius: 100%;
    border: 1px solid var(--color-green-action);
    padding: 30px;
    transition: all .5s ease;
}

.swiper-button-next:hover, .swiper-button-prev:hover {
    transform: scale(1.3);
    border: 5px solid var(--color-green-action);
}

.swiper-button-next:active, .swiper-button-prev:active {
    transform: scale(1.3);
    background-color: var(--color-green-action);
}

.swiper-button-next2, .swiper-button-prev2 {
    top:60% !important;
}

.swiper-pagination3, .swiper-pagination5, .swiper-pagination6, .swiper-pagination7, .swiper-pagination-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 80%;
    padding: 0px 0px 50px 0px;
}

.swiper-pagination5, .swiper-pagination6, .swiper-pagination7, .swiper-pagination-all {
    padding-top: 25px;
}

.swiper-pagination-bullet {
    width: 40px !important;
    height: 40px !important;
    background-color: var(--color-green-light) !important;
    border-radius: 100% !important;
    margin: 7px !important;
    display: flex !important;
    justify-content: center !important;
    opacity: 1 !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--color-green-action) !important;
    border-radius: 100%;
    border: 14px solid var(--color-green-light);
    margin: auto;
}

.slider__headline {
    font-size: var(--size-small--accent);
    font-family: var(--font-heading);
    color: var(--black);
    margin: auto;
}

.slider__headline-wrapper {
    width: 80%;
    padding-bottom: 0px;
    padding-top: 0px;
}

.slider-slider {
    position: relative;
}

.slider__single-headline {
    font-size: var(--size-small--accent);
    font-family: var(--font-heading);
    color: var(--color-green-action);
    text-align: left;
}

.slider-slider .category-slider-single-container {
    box-shadow: none;
}

.slider-slider .category-slider-single-container:hover {
    transform: scale(1.0) !important;
}

.slider-slider .category-slider-single-img {
    cursor: auto;
}

.slider-slider .category-slider-single-video {
    cursor: pointer;
}

.slider__bullet-wrapper {
    border: 2px solid var(--color-green-light);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 5px;
}

.bullet__wrapper {
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
}