/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    :root {
        font-size: 13px;
        line-height: 1.4;
        --size-heading: 2.3rem;
        --size-heading--big: 3rem;
        --size-accent: 1.4rem;
        --size-small-heading: 1.7rem;
        --size-small-subheading: 1.3rem;
        --size-text: 1rem;
        --size-small: 1rem;
        --size-extra-small: .9rem;
        --size-small--accent: 1.2rem;
    }

    header {
        height: 60px;
    }

    header {
        display: none;
        visibility: hidden;
    }

    .header-mobile {
        visibility: visible;
        display: flex;
    }

    .logo {
        padding: 0px 20px;
        height: 52px;
    }

    .hamburger {
        padding: 9px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .nav-item-jobs {
        left: 70px;
        top: 16px;
    }

    .nav-mobile-list {
        padding-top: 0;
        padding-bottom: 10px;
    }

    .button {
        height: 40px;
        min-width: 140px;
    }

    .hero, .hero-content {
        height: 450px;
    }

    .hero {
        height: 400px;
    }

    .hero-text {
        width: 100%;
    }

    .hero-content {
        height: 550px;
    }

    .hero-content--wrapper {
        margin-top: 50px;
        width: 100%;
        flex-wrap: wrap-reverse;
    }

    .hero-content--media-area {
        align-items: flex-end;
        width: 100%;
        height: 200px;
        padding-bottom: 10px;
    }

    .hero-content--content {
        width: 100%;
        height: auto;
        justify-content: flex-start;
    }

    .hero-content--style-bubble--top {
        position: absolute;
        top: -220px;
        right: -70px;
        width: 300px;
        height: 543px;
        background-color: #D8F3DC;
        border-radius: 142px;
        z-index: -1;
    }

    .background-color, .background-video {
        height: 390px;
        max-height: 390px;
        margin-top: 60px;
    }

    .hero-button__wrapper {
        margin-bottom: 30px;
    }

    .hero-video--exit {
        width: 50px;
    }

    .headline-background-text {
        font-size: 8rem;
    }

    .content-menu-with-content {
        min-height: 450px;
        height: auto;
    }

    .content-menu-with-content--wrapper {
        margin-top: 50px;
        margin-bottom: 0px;
        width: 100%;
    }

    .content-menu-with-content--media-area__active--mobile {
        display: none;
        visibility: hidden;
    }

    .content-menu-with-content--media-area__active {
        display: flex;
        visibility: visible;
    }

    .content-menu-with-content--content {
        width: 52%;
    }

    .content-menu-with-content--media-area {
        width: 45%;
    }

    .content-menu-with-content--text {
        padding: 0px 0px 15px 0px;
    }

    .open-job-list--wrapper {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
    }

    .open-job-list--filter-item {
        height: 29px;
        padding: 0px 23px;
        margin-right: 20px;
        margin-bottom: 15px;
    }

    .open-job-list--filter {
        margin-top: 10px;
        display: none;
        visibility: hidden;
    }

    .open-job-list--list {
        margin-top: 5px;
        gap: 15px;
    }

    .open-job-list--list-job {
        flex-wrap: wrap;
        height: auto;
    }

    .open-job-list--list-job-title {
        padding-top: 15px;
    }

    .open-job-list--list-job-overview {
        padding: 0px 20px;
        width: 100%;
        margin-left: 0px;
    }

    .open-job-list--list-job:hover {
        padding: 25px 0px;
        height: auto;
        background-color: white;
    }

    .open-job-list--list-job:hover .description {
        opacity: 0;
        padding: 0;
        display: none;
        visibility: hidden;
    }

    .open-job-list--list-job-cta {
        width: 100%;
        margin: 0;
        padding: 0px 20px 15px 20px;
        justify-content: space-between;
    }

    .open-job-list--list-job-cta--seperator {
        display: none;
        visibility: hidden;
    }

    .open-job-list--list-job-tags {
        padding: 5px 0px;

    }

    .open-job-list--filter {
        flex-wrap: wrap;
    }

    .center-button, .departments .center-button {
        padding-bottom: 50px;
    }

    .cta {
        height: 180px;
    }

    .cta-backgroud {
        height: 120px;
        top: -75px;
    }

    .cta-text {
        padding: 10px 0px
    }

    .departments-heading {
        padding-top: 50px;
    }

    .department-slider {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .department-slider--card {
        height: 230px;
        width: 32%;
    }

    .department-slider {
        gap: unset;
    }

    .slider__headline-wrapper {
        width: 100%;
    }

    .department-slider--card-heading {
        padding: 20px 10px 0px 10px;
    }

    .department-slider--card-text {
        padding: 10px 10px 0px 10px;
    }

    .department-slider--card-link {
        padding: 10px;
    }

    .background-img-cta {
        height: 350px;
    }

    .success-stories--heading {
        padding-top: 50px;
    }

    .success-stories--content {
        margin: 25px 0px 30px 0px;
    }

    .success-stories--content-content {
        width: 48%;
    }

    .success-stories--content-img--container {
        width: 48%;
    }

    .success-stories-heading-wrapper {
        width: 100%;
    }

    .success-stories--content-wrapper {
        padding-top: 0px;
        width: 100%;
    }

    .success-stories--content-img {
        height: 230px;
    }

    .background-decoration--left {
        height: 300px;
        width: 300px;
    }

    .background-decoration--right {
        width: 250px;
        height: 500px;
        background-color: var(--color-green-hover);
        right: -100px;
        top: 85px;
    }

    .quicklinks {
        padding: 25px;
    }

    .quicklink-content {
        height: 60px;
        padding: 0px 20px;
    }

    .arrow {
        top: 25%;
    }

    .icon-list--container {
        padding-top: 50px;
        padding-bottom: 25px;
        width: 100%;
    }

    .icon-list--headline {
        padding-bottom: 25px;
    }

    .icon-list--item {
        padding: 15px 20px;
        margin-bottom: 15px;
    }

    .icon-list--item-headline {
        margin-bottom: 5px;
    }

    .style-bubble--top-right--action {
        top: 61px;
        right: 63px;
        width: 270px;
        height: 427px;
        border-radius: 75px;
    }

    .style-bubble--bottom-left {
        bottom: 320px;
        left: -60px;
        width: 300px;
        height: 300px;
        border-radius: 48px;
    }

    .icon-list--item-icon {
        width: 55px;
        height: 55px;
        padding: 10px;
    }

    .content {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .content-media {
        height: 200px;
    }

    .content-media-img {
        height: 200px;
    }

    .content-media-video {
        max-height: 250px;
    }

    .content-text-image {
        padding: 20px 0px;
    }

    .content-background {
        height: 25%;
    }

    .content-video-section {
        padding-bottom: 25px;
    }

    .swiper-button-prev {
        display: none;
        visibility: hidden;
    }

    .swiper-button-next {
        display: none;
        visibility: hidden;
    }

    .swiper-button-next, .swiper-button-prev {
        padding: 27px;
    }

    .category-slider {
        padding-top: 20px;
        padding-bottom: 25px;
    }

    .category-slider-wrapper {
        width: 100%;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .category-slider-single-img {
        height: 125px;
    }

    .slider-slider .category-slider-single-img {
        height: 175px;
    }

    .slider-slider .category-slider-wrapper {
        width: 100%;
        padding: 20px;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .category-slider--card-link {
        padding: 0px 10px 10px 10px;
    }

    .category-slider-single-card--text {
        padding: 5px 10px;
    }

    .category-slider-single-card {
        height: 190px;
    }

    .team-bubble-wrapper {
        transform: scale(.9) translateY(-70px);
        padding: 0px;
    }

    .footer-content-container {
        width: 100%;
    }

    .footer--links {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .footer--links a {
        padding: 20px 0px 0px 0px;
    }

    .single-job--filter-item {
        padding: 6px 27px;
    }

    .job--tags {
        padding: 15px;
    }

    .job {
        padding-top: 75px;
    }

    .job--container {
        width: 100%;
        background-color: transparent;
        box-shadow: none;
    }

    .job--text-container {
        padding: 0;
    }

    .job--text-single {
        padding: 10px 0px 0px 0px;
    }

    .input__field {
        height: 30px;
    }

    input[type="file"] {
        left: 0px;
        width: 100%;
    }

    .input__label-file {
        width: 130px;
    }

    .application--form-container {
        width: 100%;
        flex-wrap: wrap;
        margin-top: 0;
        margin-bottom: 0;
    }
    .application--form-form input {
        margin-bottom: 15px;
    }

    input[type="submit"] {
        height: 30px;
    }

    .custom-file-upload {
        height: 30px;

    }

    .application--form-form-container {
        width: 100%;
    }

    .application--form-heading-container {
        width: 100%;
    }

    .application--form-heading {
        text-align: center;
    }

}