/** contetnt-menu-with-content */

.content-menu-with-content {
    min-height: 600px;
    height: auto;
    width: 100%;
    background-color: var(--color-green-light);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.content-menu-with-content--wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 100px;

}

.content-menu-with-content--content {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.content-menu-with-content--menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: var(--black);
    padding: 0px 0px 10px 0px;
}

.content-menu-with-content--menu-items {
    font-family: var(--font-accent);
    font-size: var(--size-small--accent);
    cursor: pointer;
    position: relative;
}

.content-menu-with-content--menu-items::after {
    content: "";
    width: 3px;
    height: 3px;
    background-color: var(--color-green-action);
    position: absolute;
    left: calc(50% - 4px);
    bottom: 0px;
    opacity: 0;
}

.content-menu-with-content--menu-items:hover::after {
    content: "";
    width: 100%;
    height: 6px;
    background-color: var(--color-green-dark);
    position: absolute;
    left: 0%;
    bottom: 0px;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    transition: width .7s ease-in-out, left .7s ease-in-out, height .3s ease-in-out .7s;
}

.content-menu-with-content--menu-items__active:hover::after {
    opacity: 0;
}

.content-menu-with-content--menu-items__active {
    border-bottom: 3px solid var(--color-green-dark);
}

.content-menu-with-content--media-area {
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
    height: 350px;
    display: none;
    visibility: hidden;
}

.content-menu-with-content--media-area__active {
    display: flex;
    visibility: visible;
}

.content-menu-with-content--media {
    min-width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    height: 100%;
}


.content-menu-with-content--heading {
    color: var(--black);
    font-family: var(--font-heading);
    font-size: var(--size-small-heading);
    text-align: left;
    padding: 10px 0px;
}

.content-menu-with-content--text {
    font-family: var(--font-text);
    font-size: var(--size-text);
    padding: 0px 0px 30px 0px;
}
.content-menu-with-content--content-overlay {
    display: none;
    visibility: hidden;
    width: 100%;
}

.content-menu-with-content--content-overlay__active {
    display: flex;
    flex-direction: column;
    visibility: visible;
}

.change-background-color {
    transition: all .5s ease-in-out;
}

.content-menu-with-content--picture {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}

.content-menu-with-content--media-area-mobile {
    width: 100%;
    display: none;
    visibility: hidden;
}

.content-menu-with-content--media-mobile {
    min-width: 100%;
    max-width: 100%;
}