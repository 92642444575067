/** q and a */

.q-and-a {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
}

.q-and-a--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    height: 100%;
    margin: auto;
    padding: 50px 0px;
}

.q-and-a--q {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    background-color: white;
    border-radius: 4px;
    margin: 30px 0px;
    color: var(--black);
    font-style: italic;
    margin-right: auto;
    height: min(70px, auto);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.q-and-a--q-text {
    padding: 20px 20px;
    line-height: 1.5;
    width: 100%;
}

.q-and-a--a {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
    background-color: #D8F3DC;
    border-radius: 4px;
    margin-right: 0;
    margin-left: auto;
    color: var(--black);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.q-and-a--q-img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 100%;
    margin: 15px;
}

.q-and-a--heading {
    color: var(--black);
    font-size: 3rem;
    padding-bottom: 20px;
    font-weight: bolder;
    padding-top: 50px;
}