.content {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 80%;
    position: relative;
    /*background-color: white;*/
}

.content-headline {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    color: var(--black);
    text-align: left;
}

.content-text-image {
    padding: 25px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.content-text {
    width: 48%;
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
}

.content-media {
    min-width: 48%;
    max-width: 48%;
    max-height: 300px;
    height: 300px;
    object-fit: cover;
}

.content-media-img {
    min-width: 100%;
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    object-position: top;
    height: 300px;
}



.content-video {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-video-section {
    padding-bottom: 50px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-background {
    position: absolute;
    width: 100vw;
    height: 45%;
    background-color: var(--color-green-deco);
    z-index: -1;
}

.content-video-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
}

.content-media-video {
    min-width: 100%;
    max-width: 100%;
    max-height: 500px;
    object-fit: cover;
}

.content-video-headline {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    color: var(--black);
    padding-bottom: 20px;
    padding-top: 25px;
}