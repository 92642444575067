/** job */

/** headline with text and media */

.job {
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-end;
    padding-top: 150px;
    position: relative;
    z-index: 1;
}

.job--container {
    height: auto;
    width: 80%;
    border-radius: 4px;
    padding-bottom: 0;
}

.job--headline {
    font-size: var(--size-heading);
    font-family: var(--font-heading);
    color: var(--black);
    text-align: center;
}

.job--tags {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: auto;
    padding: 25px 0px;
    font-size: var(--size-text);
    font-family: var(--font-accent);
    color: var(--black);
}

.single-job--filter-item {
    padding: 8px 27px;
    border-radius: 100px;
    margin-right: 40px;
    display: block;
    background-color: #95D5B2;
}

.job--text-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto;
    padding: 20px 0 0px 0px;
}

.job--text-single--headline {
    font-size: var(--size-small--accent);
    font-family: var(--font-heading);
    color: var(--black);
}

.job--text-single {
    padding: 10px 0 10px 0;
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
}



.job--text-single li {
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
}

.job--text-single ul li {
    width: auto;
    padding: 5px 15px 5px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.job--text-single ul li::before {
    content: '';
    margin: auto;
    margin-right: 15px;
    margin-left: 20px;
    width: 10px;
    min-width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    background-color: #52B788;
}

.job--text-single strong {
    font-family: var(--font-heading);
}

.job--text-single--list {
    padding: 15px 0;
}

.job--text-single--list-item {
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-direction: row;
}

.job--text-single--list-item--bulletcontainer {
    width: auto;
    padding: 0 15px 0px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.job--text-single--list-item--bullet {
    margin: auto;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    background-color: #52B788;
}

.job--text-single--pure-text {
    padding: 15px 0px 15px 0px;
}

.job-text {
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
}