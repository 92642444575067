/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
    :root {
        font-size: 14px;
        line-height: 1.4;
        --size-heading: 3rem;
        --size-heading--big: 5rem;
        --size-accent: 1.7rem;
        --size-small-heading: 2.3rem;
        --size-small-subheading: 1.5rem;
        --size-text: 1rem;
        --size-small: 1rem;
        --size-extra-small: .9rem;
        --size-small--accent: 1.2rem;
    }

    .hero, .hero-content {
        height: 600px;
    }

    .background-color, .background-video {
        height: 600px;
        max-height: 600px;
    }

    .content-menu-with-content {
        min-height: 550px;
        height: auto;
    }

    .content-menu-with-content--wrapper {
        margin-top: 75px;
        margin-bottom: 0px;
    }

    .open-job-list--wrapper {
        padding-top: 75px;
        padding-bottom: 75px;
    }

    .open-job-list--filter-item {
        height: 33px;
        padding: 0px 30px;
    }

    .open-job-list--filter {
        margin-top: 15px;
    }

    .open-job-list--list {
        margin-top: 25px;
    }

    .open-job-list--list-job {
        height: 175px;
    }

    .center-button, .departments .center-button {
        padding-bottom: 75px;
    }

    .cta {
        height: 235px;
    }

    .cta-backgroud {
        height: 180px;
        top: -75px;
    }

    .cta-text {
        padding: 10px 0px
    }

    .departments-heading {
        padding-top: 75px;
    }

    .button {
        height: 45px;
        min-width: 150px;
    }

    .background-img-cta {
        height: 475px;
    }

    .success-stories--heading {
        padding-top: 75px;
    }

    .success-stories--content {
        margin: 25px 0px 30px 0px;
    }

    .success-stories--content-img {
        height: 270px;
    }

    .quicklink-content {
        height: 60px;
        padding: 0px 20px;
    }

    .arrow {
        top: 25%;
    }

    .icon-list--container {
        padding-top: 75px;
        padding-bottom: 50px;
    }

    .icon-list--item {
        padding: 25px 30px;
    }

    .icon-list--item-icon {
        width: 55px;
        height: 55px;
        padding: 10px;
    }

    .content-media {
        height: 270px;
    }

    .content-media-img {
        height: 270px;
    }

    .content-media-video {
        max-height: 450px;
    }

    .swiper-button-prev {
        left: 10px !important;
    }

    .swiper-button-next {
        right: 10px !important;
    }

    .category-slider-wrapper {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .category-slider-single-img {
        height: 175px;
    }

    .slider-slider .category-slider-single-img {
        height: 200px;
    }

    .slider-slider .category-slider-wrapper {
        padding: 20px;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .category-slider--card-link {
        padding: 0px 15px 10px 15px;
    }

    .category-slider-single-card--text {
        padding: 10px 15px;
    }

    .team-bubble-wrapper {
        transform: scale(.9) translateY(-70px);
        padding: 0px;
    }

    .single-job--filter-item {
        padding: 6px 27px;
    }

    .job--tags {
        padding: 15px;
    }

    .job {
        padding-top: 125px;
    }

    .job--container {
    }

    .input__field {
        height: 30px;
    }

    .application--form-container {
    }

    input[type="submit"] {
        height: 35px;
    }

    .custom-file-upload {
        height: 30px;
    }
}