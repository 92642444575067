/** icon-list */

.icon-list {
    position: relative;
    z-index: 0;
}

.icon-list--background-icon {
    position: absolute;
    width: 40%;
    height: auto;
    opacity: 0.1;
    color: #2F4AB2;
    right: -100px;
    top: 200px;
    transform:rotate(10deg);
    z-index: -1;
}

.icon-list--background-icon--bottom {
    position: absolute;
    width: 40%;
    height: auto;
    opacity: 0.1;
    color: #EFFAF1;
    left: -100px;
    bottom: 200px;
    transform: rotate(10deg);
    z-index: -1;
}

.icon-list--headline {
    color: var(--black);
    font-family: var(--font-heading);
    font-size: var(--size-small-heading);
    padding-bottom: 30px;
}

.icon-list--container {
    padding-top: 100px;
    padding-bottom: 100px;
    width: 80%;
}

.icon-list--content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.icon-list--item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--color-green-light);
    padding: 40px 40px;
    margin-bottom: 30px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    border-radius: 4px;
}

.icon-list--item__shared {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 50px 0px;
}

.icon-list--item-icon {
    height: 65px;
    width: 65px;
    border-radius: 100%;
    background-color: var(--color-green-action);
    padding: 15px;
    margin-right: 20px;
}

.icon-list--item-icon__big {
    height: auto;
    width: 35%;
    background-color: #F8CFD3;
    border-radius: 4px;
}

.icon-list--item-headline {
    color: var(--black);
    font-size: var(--size-small--accent);
    font-family: var(--font-heading);
    margin-bottom: 10px;
}

.icon-list--item-text {
    color: var(--black);
    font-size: var(--size-text);
    font-family: var(--font-text);
}

.icon-list--item-content__shared {
    width: 60%;
}