/** background-img-cta */

.background-img-cta {
    width: 100%;
    height: 500px;
    position: relative;
    display: flex;
    align-items: center;
}

.background-img-cta--img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.background-img-cta--wrapper {
    position: relative;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    gap: 40px;
}

.background-img-cta--styling {
    width: 10px;
    background-color: var(--color-green-action);
    border-radius: 4px;
}

.background-img-cta--content {
    background-color: var(--color-green-light);
    width: 95%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    padding: 40px 40px 40px 80px;
}

.background-img-cta--content-heading {
    font-family: var(--font-heading);
    font-size: var(--size-small-subheading);
    color: var(--black);
}

.background-img-cta--content-text {
    font-family: var(--font-text);
    font-size: var(--size-text);
    color: var(--black);
    width: 300px;
}
