/** success stories */
.success-stories {
    background-color: var(--white);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.success-stories--heading {
    font-size: var(--size-small-heading);
    font-family: var(--font-heading);
    color: var(--black);
    padding-top: 100px;
    width: 100%;
    text-align: left;
}

.success-stories--content-wrapper {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-stories--content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    transition: background-color .3s ease;
    position: relative;
    margin: 40px 0px;
}

.success-stories--content-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 47%;
}

.success-stories--content-heading {
    font-size: var(--size-small-subheading);
    font-family: var(--font-heading);
    color: var(--black);
    text-align: left;
}

.success-stories-heading-wrapper {
    width: 80%;
    padding-bottom: 0px;
}

.success-stories--content-text {
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
    padding: 10px 0px 20px 0px;
}

.button-small {
    height: 25px;
    width: 85px;
    border: none;
    border-radius: 100px;
    background-color: #74C69D;
    color: var(--black);
    cursor: pointer;
}

.button-small:hover {
    background-color: #D8F3DC;
}

.success-stories--content-img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}

.success-stories--content-img--container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 47%;
}

.success-stories--content-img--container-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 47%;
}

.right-success-stories--content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #52B788;
    height: 350px;
    width: 66%;
    padding: 60px 200px 60px 100px;
}

.right-success-stories--content-content {
}

.right-success-stories--content-img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 4px;
    position: relative;
    top: -80%;
    left: 20%;
    cursor: pointer;
}

.right-success-stories--content-styling--horizontal {
    position: absolute;
    height: 10px;
    width: 80vw;
    background-color: #2F4AB2;
    border-radius: 4px;
    top: 250px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    right: -45vw;
}

.right-success-stories--content-styling--vertical {
    position: absolute;
    height: 600px;
    width: 10px;
    background-color: #2F4AB2;
    border-radius: 4px;
    right: 35vw;
    top: -200px;
}

.right-success-stories--content-styling {
    display: flex;
    flex-direction: row;
    position: absolute;
}

.success-stories--content-styling--vertical__bottom {
    position: absolute;
    height: 600px;
    width: 10px;
    background-color: #2F4AB2;
    border-radius: 4px;
    left: 80vw;
    top: -200px;
}

.background-decoration--right {
    position: absolute;
    width: 300px;
    height: 600px;
    border-radius: 140px;
    display: inline-block;
    background-color: #95D5B2;
    right: -140px;
    top: 50px;
}

.background-decoration--left {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 40%;
    display: inline-block;
    background-color: #D8F3DC;
    left: 70px;
    bottom: 80px;
}
