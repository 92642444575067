.frc-captcha {
    background-color: transparent !important;
    margin-bottom: 20px !important;
    min-width: auto !important;
    max-width: none !important;
    border: 1px solid var(--color-green-deco) !important;
}

.frc-icon {
    fill: var(--color-green-action) !important;
    stroke: var(--color-green-action) !important;
}

.frc-container {
    min-height: 40px !important;
}