footer {
    width: 100%;
    background-color: #081C15;
    color: white;
}

.footer-content-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 80%;
}

.footer--company {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.footer--company div {
    padding: 7px 0px 0px 0px;
}

.footer--company-text {
    font-family: var(--font-accent);
    font-size: var(--size-text);
}

.footer--links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.footer--links a {
    color: white;
    font-family: var(--font-heading);
    font-size: var(--size-extra-small-text);
    padding: 0px 20px;
}

.footer--copyright {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-family: var(--font-accent);
    font-size: var(--size-text);
}

.footer-logo-img {
    width: 150px;
}

.empty {
    width: 100%;
    height: auto;
}

.empty-wrapper {
    width: 80%;
    padding-top: 150px;
    padding-bottom: 100px;
}