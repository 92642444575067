.hero-content {
    height: 650px;
    width: 100%;
    background-color: var(--color-green-light);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.hero-content--wrapper {
    display: flex;
    justify-content: space-between;
    width: 80%;
    height: 80%;
    margin-top: 110px;
    margin-bottom: auto;
}

.hero-content--content {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero-content--content-overlay {
    display: flex;
    flex-direction: column;
    visibility: visible;
}

.hero-content--heading {
    font-size: var(--size-heading);
    font-family: var(--font-heading);
    color: var(--black);
    text-align: left;
}

.hero-content--text {
    padding: 10px 0px 20px 0px;
    font-size: var(--size-text);
    font-family: var(--font-text);
    color: var(--black);
}

.hero-content--media-area {
    justify-content: center;
    align-items: center;
    width: 55%;
    height: 100%;
    display: flex;
    visibility: visible;
}

.hero-content--media {
    min-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-content--style-bubble--top {
    position: absolute;
    top: -220px;
    right: -70px;
    width: 364px;
    height: 693px;
    background-color: #D8F3DC;
    border-radius: 142px;
    z-index: -1;
}

.hero-content--style-bubble--bottom {
    position: absolute;
    bottom: 20px;
    left: -150px;
    width: 323px;
    height: 306px;
    background-color: #D8F3DC;
    border-radius: 142px;
    z-index: -1;
}

.style-bubble--top-right {
    position: absolute;
    top: 140px;
    right: -190px;
    width: 364px;
    height: 693px;
    background-color: var(--color-green-light);
    border-radius: 142px;
    z-index: -1;
}

.style-bubble--top-right--action {
    position: absolute;
    top: 100px;
    right: 30px;
    width: 364px;
    height: 693px;
    background-color: var(--color-green-action);
    border-radius: 142px;
    z-index: -1;
}

.style-bubble--bottom-left {
    position: absolute;
    bottom: 320px;
    left: -60px;
    width: 400px;
    height: 400px;
    background-color: #D8F3DC;
    border-radius: 142px;
    z-index: -1;
}